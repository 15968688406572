import {createContext} from "react";
import User from "../../api/user/User";

type AuthContextType = {
    /**
     * Current authenticated user.
     * User will be loaded when program's starting.
     * Use reload() to reload this user.
     */
    user?: User;

    /**
     * Reload current user in context.
     * It will call /api/me/ to get current user.
     * **If server will respond with error, will set user to null**
     */
    reload: () => void;

    /**
     * Login with username and password.
     * Returns current authenticated user or null, if authentication failed.
     * Please be aware that method will instantly call /api/me/ after receiving an access token.
     * @param username User name
     * @param password User password
     * @returns Authenticated user or null - if authentication failed.
     */
    login: (username: string, password: string) => Promise<User>;

    /**
     * Deletes access token from storage and clears context.
     * @author Kacper Faber
     */
    logout: () => void;
}

const notImplemented = () => {
    throw new Error('This method is not implemented at this point. Please ensure you\'re in AuthContext.')
}

const initial: AuthContextType = {
    user: undefined,
    reload: notImplemented,
    login: notImplemented,
    logout: notImplemented
}

const AuthContext = createContext<AuthContextType>(initial);

export default AuthContext;

