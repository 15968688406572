import {useEffect, useState} from "react";
import ComputerConfig from "../../../api/computer-config/ComputerConfig";
import {Button, Checkbox, Modal, NumberInput, Stack, Tabs} from "@mantine/core";
import DialogProps from "../../../pages/components/DialogProps";
import Program from "../../../api/program/Program";
import Page from "../../../api/Page";
import ProgramApi from "../../../api/program/ProgramApi";
import Notifications from "../../../utils/notifications/Notifications";

type EditDefaultConfigDialogBaseProps = {
    /**
     * Action when user clicked save.
     * Must return promise of a pending http request.
     * @param a Data to save
     */
    save: (a: SaveData) => Promise<unknown>;

    /**
     * Modal title
     * @author Kacper Faber
     */
    title: string,

    /**
     * Configuration to edit.
     * @author Kacper Faber
     */
    config: ComputerConfig | null | undefined;
}

type SaveData = { programIds: number[], maxIdleSeconds: number };

type EditDefaultConfigDialogProps = EditDefaultConfigDialogBaseProps & DialogProps;

function ComputerConfigEditDialog(props: EditDefaultConfigDialogProps) {
    /**
     * Picked value for maxIdleSeconds.
     * @author Kacper Faber
     */
    const [max, setMax] = useState<number>();

    /**
     * Selected program IDs.
     * Loaded at start
     */
    const [selectedProgramIds, setSelectedProgramIds] = useState<number[]>([]);

    const [programs, setPrograms] = useState<Page<Program>>();

    const initState = () => {
        setMax((props.config?.maxIdleSeconds ?? 0) / 60);
        setSelectedProgramIds(props.config?.programs?.map(x => x.id) ?? []);
    }

    const initPrograms = () => {
        ProgramApi.getPrograms({})
            .then(setPrograms)
            .catch(Notifications.showFetchError);
    }

    const onProgramClicked = (program: Program, value: boolean) => {
        if (value) {
            setSelectedProgramIds([...selectedProgramIds, program.id]);
            return;
        }

        setSelectedProgramIds([...selectedProgramIds].filter(x => x !== program.id));
    }

    const renderProgram = (program: Program) => {
        return (
            <Checkbox
                label={program.title}
                checked={selectedProgramIds?.includes(program.id) ?? false}
                onChange={ev => onProgramClicked(program, ev.target.checked)}
            />
        );
    }

    const onSave = () => {
        props.save({maxIdleSeconds: (max ?? 0) * 60, programIds: selectedProgramIds})
            .then(Notifications.showUpdateSuccess)
            .then(props.closeAndReload)
            .catch();
    }

    useEffect(initState, [props.config]);

    useEffect(initPrograms, [props.config, props.opened])

    return (
        <Modal opened={props.opened} onClose={props.justClose} title={props.title}>
            <Stack>
                <Tabs defaultValue="maxIdleSeconds">
                    <Tabs.List>
                        <Tabs.Tab value="maxIdleSeconds">
                            Czas bezczynności
                        </Tabs.Tab>

                        <Tabs.Tab value="programs">
                            Programy
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value={"maxIdleSeconds"}>
                        <NumberInput
                            my="md"
                            label="Czas bezczynności"
                            description="W minutach"
                            value={max}
                            onChange={(e) => setMax(e as number)}
                            suffix=" minut"
                        />
                    </Tabs.Panel>

                    <Tabs.Panel value={"programs"}>
                        <Stack my="md">
                            { programs?.results?.map(renderProgram) }
                        </Stack>
                    </Tabs.Panel>
                </Tabs>

                <Button onClick={onSave}>Zapisz</Button>
            </Stack>
        </Modal>
    );
}

export default ComputerConfigEditDialog;