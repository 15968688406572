import PageWrapper from "../../PageWrapper";
import {ActionIcon, Group, Menu, Tabs, Title} from "@mantine/core";
import {useEffect, useState} from "react";
import Computer from "../../../api/computer/Computer";
import ComputerApi from "../../../api/computer/ComputerApi";
import {useParams} from "react-router-dom";
import Notifications from "../../../utils/notifications/Notifications";
import {IconDotsVertical} from "@tabler/icons-react";
import ComputerDetailsSummary from "./summary/ComputerDetailsSummary";

function ComputerDetails() {
    const params = useParams<any>();
    const [computer, setComputer] = useState<Computer>();

    const reload = () => {
        const id = parseInt(params.computerId!!);

        ComputerApi.getComputer(id)
            .then(setComputer)
            .catch(Notifications.showFetchError);
    };

    useEffect(reload, [params]);

    return (
        <PageWrapper mode="simple_animated">
            <Group justify="space-between" mb="xl">
                <Title>Komputer {computer?.title ?? 'bez tytułu'}</Title>

                <Menu>
                    <Menu.Target>
                        <ActionIcon variant="transparent">
                            <IconDotsVertical/>
                        </ActionIcon>
                    </Menu.Target>
                </Menu>
            </Group>

            <Tabs defaultValue="summary">
                <Tabs.List>
                    <Tabs.Tab value="summary">
                        Podsumowanie
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="summary" py="md">
                    <ComputerDetailsSummary
                        reload={reload}
                        computer={computer}
                    />
                </Tabs.Panel>
            </Tabs>
        </PageWrapper>
    );
}

export default ComputerDetails;