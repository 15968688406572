import {AppShell, Burger, Group} from "@mantine/core";
import CurrentUser from "./CurrentUser";

type HeaderProps = {
    mobileOpened: boolean;
    desktopOpened: boolean;

    /**
     * Toggle mobile
     * @author Kacper Faber
     */
    toggleMobile: () => void;

    /**
     * Toggle desktop state.
     * @author Kacper Faber
     */
    toggleDesktop: () => void;
};

/**
 * Main application header.
 * To use in Layout.
 * It's using Mantine's AppShell - must be in AppShell context.
 * @param props
 * @constructor
 */
// noinspection SpellCheckingInspection
function Header(props: HeaderProps) {
    return (
        <AppShell.Header bg="dark.8" withBorder={false}>
            <Group h="100%" justify="space-between" px="md">
                <Group h="100%">
                    <Burger
                        opened={props.mobileOpened}
                        onClick={props.toggleMobile}
                        hiddenFrom="sm"
                        size="sm"
                    />

                    <Burger
                        opened={props.desktopOpened}
                        onClick={props.toggleDesktop}
                        visibleFrom="sm"
                        size="sm"
                    />
                </Group>

                <CurrentUser/>
            </Group>
        </AppShell.Header>
    );
}

export default Header;

// TODO: Header.tsx
//  - Implement current user information, sign out etc.