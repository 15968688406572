import Api from "../Api";
import HttpClient from "../HttpClient";
import User from "../user/User";

export type AuthenticatePayload = {
    username: string;
    password: string;
}

export type AuthenticationResult = {
    accessToken: string;
}

class AuthenticationApi {
    /**
     * POST /api/auth/
     * Authenticate user with username and password.
     * Want to get an access token.
     * @param payload {username, password}
     */
    // noinspection JSUnusedGlobalSymbols
    static authenticate(payload: AuthenticatePayload): Promise<AuthenticationResult> {
        const url = Api.getUrl('/api/auth/');
        return HttpClient.post(url, {}, { ...payload });
    }

    /**
     * GET /api/me/
     *
     * Returns current authenticated user or invalid status code,
     * if user is not authenticated.
     *
     * You can use then/catch/finally to query this API.
     * Catch will be raised if server respond with not positive status code.
     */
    // noinspection JSUnusedGlobalSymbols
    static me(): Promise<User> {
        const url = Api.getUrl('/api/me/user/');
        return HttpClient.get(url, {});
    }
}

// noinspection JSUnusedGlobalSymbols
export default AuthenticationApi;