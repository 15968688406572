class Api {
    /**
     * Create a URL for specified path. For an example: /auth -> http://localhost/auth
     * @param path Resource path.
     * @returns string URL
     */
    static getUrl(path: string): string {
        const baseUrl = process.env.REACT_APP_API_URL;
        return `${baseUrl}${path}`;
    }
}

export default Api;