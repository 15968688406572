import {BrowserRouter, Route as BrowserRoute, Routes as BrowserRoutes} from "react-router-dom";
import LoginPage from "../pages/login/Login";
import SecuredRoute from "./SecuredRoute";
import Layout from "../pages/_layout/Layout";
import Routes from './Routes';
import {Route} from './Routes';

function AppRouter() {
    /**
     * Render <Route> element.
     * @param route - route definition.
     * @author Kacper Faber
     */
    const render = (route: Route) => {
        return (
            <BrowserRoute path={route.path} element={route.element}/>
        );
    }

    return (
        <BrowserRouter>
            <BrowserRoutes>
                <BrowserRoute
                    path="/login"
                    element={<LoginPage/>}
                />

                <BrowserRoute element={<SecuredRoute children={<Layout/>}/>}>
                    { Routes.map(render) }
                </BrowserRoute>
            </BrowserRoutes>
        </BrowserRouter>
    );
}

export default AppRouter;