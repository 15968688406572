import {ReactNode} from "react";
import {Navigate} from "react-router-dom";
import Storage from "../storage/Storage";

type SecuredRouteProps = {
    children: ReactNode;
}

/**
 * Renders children only if user is authenticated.
 * Otherwise, will redirect to /login page.
 *
 * We're looking for access token in storage.
 * If it's invalid, we won't redirect [but API will block requests with invalid auth].
 * It's better solution that using AuthContext, because context needs to initialize first.
 * @param props
 * @constructor
 */
function SecuredRoute(props: SecuredRouteProps) {
    // If unauthenticated, redirect to login page
    if (Storage.getAccessToken() === null) {
        return (
            <Navigate to="/login" replace/>
        );
    }

    // If authenticated, return children.
    return (
        <>
            {props.children}
        </>
    );
}

export default SecuredRoute;

// TODO: SecuredRoute
//  Figure out how we can test this component (and other).