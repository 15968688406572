import Stats from "./Stats";
import Api from "../Api";
import HttpClient from "../HttpClient";

class StatsApi {
    static getStats(): Promise<Stats> {
        const url = Api.getUrl('/api/stats/');
        return HttpClient.get(url);
    }
}

export default StatsApi;