import Computer from "../../../../api/computer/Computer";
import HoverCard from "../../../../components/hover-card/HoverCard";
import {ActionIcon, Button, Group, Menu, SimpleGrid, Text, Title} from "@mantine/core";
import {useState} from "react";
import ComputerConfigEditDialog from "../../../../components/computer-config/edit-dialog/ComputerConfigEditDialog";
import ComputerConfigApi from "../../../../api/computer-config/ComputerConfigApi";
import {IconDotsVertical, IconTrash} from "@tabler/icons-react";
import Notifications from "../../../../utils/notifications/Notifications";

type ComputerDetailsSummaryTabProps = {
    computer: Computer | undefined;
    reload: () => void;
}

function ComputerDetailsSummary(props: ComputerDetailsSummaryTabProps) {
    const [editConfig, setEditConfig] = useState(false);

    const onSaveConfig = (s: { maxIdleSeconds: number, programIds: number[] }) => {
        return ComputerConfigApi.edit({...s, computerId: props.computer?.id!!, useCustomConfig: true});
    }

    const useDefaultConfig = () => {
        const q = {
            maxIdleSeconds: 0,
            useCustomConfig: false,
            programIds: [],
            computerId: props.computer?.id!!
        };

        ComputerConfigApi.edit(q)
            .then(Notifications.showUpdateSuccess)
            .then(props.reload)
            .catch(Notifications.showFetchError);
    }

    return (
        <>
            <SimpleGrid cols={{base: 2, xl: 3, md: 2, sm: 1, xs: 1}}>
                <HoverCard>
                    <Title>
                        {props.computer?.killCount}
                    </Title>
                    <Text>Łącznie zabójstw</Text>
                </HoverCard>

                <HoverCard>
                    <Group justify="space-between">
                        <Title>
                            Konfiguracja
                        </Title>

                        {props.computer?.config ? <Menu>
                                <Menu.Target>
                                    <ActionIcon variant="transparent">
                                        <IconDotsVertical/>
                                    </ActionIcon>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Item color="red" leftSection={<IconTrash/>} onClick={useDefaultConfig}>
                                        Użyj domyślnych ustawień
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                            : null}
                    </Group>
                    {props.computer?.config ? <Text c="dimmed">Ten komputer nadpisuje ustawienia.</Text> : null}
                    {props.computer?.config ? null :
                        <Text c="dimmed">Ten komputer korzysta z ustawień domyślnych.</Text>}

                    <Button
                        variant="default"
                        mt="md"
                        onClick={() => setEditConfig(true)}
                        children="Edytuj"
                    />
                </HoverCard>
            </SimpleGrid>

            <ComputerConfigEditDialog
                save={onSaveConfig}
                title="Edytuj konfigurację"
                config={props.computer?.config}
                opened={editConfig}
                justClose={() => setEditConfig(false)}
                closeAndReload={() => {
                    setEditConfig(false);
                    props.reload();
                }}
            />
        </>
    );
}

export default ComputerDetailsSummary;