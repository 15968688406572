import {ReactNode} from "react";
import {Button, createTheme, MantineProvider} from "@mantine/core";

// Import mantine styles.
import '@mantine/core/styles.css';
import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.css';
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

const theme = createTheme({
    components: {
        Button: Button.extend({
            defaultProps: {
                radius: 0,
                fs: 'xs'
            }
        })
    },

    colors: {
        dark: [
            "#C1C2C5",
            "#A6A7AB",
            "#909296",
            "#5c5f66",
            "#373A40",
            "#2C2E33",
            "#25262b",
            "#1A1B1E",
            "#141517",
            "#101113",
        ]
    }
});

/**
 * Wraps your children inside a MantineProvider block.
 * Component configures everything about mantine.
 * @param props
 * @constructor
 */
function WrapMantine(props: {children: ReactNode}) {
    return (
        <MantineProvider defaultColorScheme="dark" theme={theme}>
            <Notifications/>

            <ModalsProvider>
                {props.children}
            </ModalsProvider>
        </MantineProvider>
    );
}

export default WrapMantine;