import {createContext, Dispatch, SetStateAction} from "react";
import ListQuery from "../../../api/computer/ListQuery";
import Computer from "../../../api/computer/Computer";
import Page from "../../../api/Page";

type ComputerPageContextType = {
    /**
     * Query to search computers.
     * Computer page will reload data [page], if query is changed.
     * @author Kacper Faber
     */
    query: [ListQuery, Dispatch<SetStateAction<ListQuery>>];

    /**
     * Is fetching data?
     * Computer page set to true, when he starts to reload data.
     * @author Kacper Faber
     */
    isFetching: boolean;

    /**
     * Resolved data.
     * Can be undefined.
     * @author Kacper Faber
     */
    page: [Page<Computer> | undefined, Dispatch<SetStateAction<Page<Computer> | undefined>>];

    /**
     * Computer to edit.
     * If set, ComputerEditDialog will be shown.
     * @author Kacper Faber
     */
    computerToEdit: [Computer | undefined, Dispatch<SetStateAction<Computer | undefined>>];
}

/**
 * Default implementation for methods.
 * Throws not implemented.
 * @author Kacper Faber
 */
const notImpl = () => { throw new Error('Not implemented'); }

/**
 * Initial state.
 * @author Kacper Faber
 */
const initialState: ComputerPageContextType = {
    query: [{}, notImpl],
    page: [undefined, notImpl],
    computerToEdit: [undefined, notImpl],
    isFetching: false
}

/**
 * Computer page context.
 * Computer page will embrace content within context.
 * And all components, dialogs, tables etc. they will be able to use this data.
 */
const ComputerPageContext = createContext<ComputerPageContextType>(initialState);

export default ComputerPageContext;