import {useEffect, useState} from "react";
import Page from "../../api/Page";
import Kill from "../../api/kill/Kill";
import KillApi from "../../api/kill/KillApi";
import ListQuery from "../../api/kill/ListQuery";
import Notifications from "../../utils/notifications/Notifications";
import PageWrapper from "../PageWrapper";
import KillView from "./view/KillView";
import {Title} from "@mantine/core";

/**
 * Initial ListQuery for KillApi.
 * @author Kacper Faber
 */
const initQuery: ListQuery = {};

function KillPage() {
    /**
     * Stores results from API.
     * @author Kacper Faber
     */
    const [page, setPage] = useState<Page<Kill>>();

    /**
     * Is data loading.
     * You can use predefined methods [startLoading, stopLoading]
     * @author Kacper Faber
     */
    const [loading, setLoading] = useState(false);

    /**
     * Methods to change 'loading' state.
     * @author Kacper Faber
     */
    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    /**
     * Stores query.
     * Initially set to 'initQuery'.
     * @author Kacper Faber
     */
    const [query, setQuery] = useState<ListQuery>(initQuery);

    /**
     * Refresh data from API.
     * It's using [query state] to request an API.
     * Results will be set to [page state].
     */
    const reload = () => {
        startLoading();

        KillApi.getKills(query)
            .then(setPage)
            .catch(Notifications.showFetchError)
            .then(stopLoading);
    }

    useEffect(reload, [query]);

    return (
        <PageWrapper page="kill_list">
            <Title mb="xl">
                Zamknięcia programów
            </Title>

            <KillView
                page={page}
                query={query}
                setQuery={setQuery}
                loading={loading}
            />
        </PageWrapper>
    );
}

export default KillPage;

// TODO: Kill.tsx
//  - Implement Kill page.