import {showNotification} from "@mantine/notifications";

class Notifications {
    /**
     * Constant text to use.
     * 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem.'
     */
    static ifProblem: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem.'

    static ifYouLostYourPassword: 'Jeśli nie pamiętasz hasła, poproś administratora o jego zresetowanie.';

    /**
     * Show notification about fetch error.
     * Common use after received invalid response from API, while fetching some data.
     *
     * Shows:
     * - Nie udało się pobrać danych
     * - Jeśli problem nie ustąpi, proszę skontaktować się z administratorem
     */
    // noinspection JSUnusedGlobalSymbols
    static showFetchError() {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: Notifications.ifProblem,
            color: 'red',
            bg: 'red'
        });
    }

    /**
     * Show notification about fetch error.
     * Common use after received invalid response from API, while fetching some data.
     *
     * Shows:
     * - Nie udało się pobrać danych
     * - Jeśli problem nie ustąpi, proszę skontaktować się z administratorem
     */
    // noinspection JSUnusedGlobalSymbols
    static showLoginError() {
        showNotification({
            title: 'Nie udało się zalogować do systemu',
            message: Notifications.ifYouLostYourPassword,
            color: 'red',
            bg: 'red'
        });
    }

    /**
     * Show notification about logged out successfully.
     *
     * Shows:
     * - Wylogowano pomyślnie
     * - (Empty string)
     */
    static showLogoutSuccess() {
        showNotification({
            title: 'Wylogowano pomyślnie!',
            message: '',
            bg: 'red',
            color: 'red'
        })
    }

    /**
     * Show notification about resource successfully updated.
     * Text 'Saved' also matching for resource created.
     *
     * Shows:
     * - Zapisano
     * -
     */
    // noinspection JSUnusedGlobalSymbols
    static showUpdateSuccess() {
        showNotification({
            title: 'Zapisano',
            message: '',
            bg: 'green',
            color: 'green'
        });
    }
}

// noinspection JSUnusedGlobalSymbols
export default Notifications;