import {BackgroundImage, Button, Container, Paper, PasswordInput, rem, TextInput, Transition,} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../contexts/auth/AuthContext";
import {useForm} from "@mantine/form";
import Notifications from "../../utils/notifications/Notifications";
import {useNavigate} from "react-router-dom";
import backgroundImage from "../../assets/login_background.avif"

/**
 * Login form structure
 * @author Kacper Faber
 */
type LoginFormData = {
    username: string;
    password: string;
}

function LoginPage() {
    /**
     * State we're using to animate login form.
     * After component mounted we set to true, and that's it.
     * @author Kacper Faber
     */
    const [isFormMounted, setIsFormMounted] = useState(false);
    const [loading, setLoading] = useState(false);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    /**
     * Sets loading to false.
     * @author Kacper Faber
     */
    const stopLoading = () => {
        setLoading(false);
    }

    /**
     * On component mounted.
     * Related with useEffect(onMounted, [])
     */
    const onMounted = () => {
        setIsFormMounted(true);
    }

    const form = useForm<LoginFormData>({
        mode: 'uncontrolled',
        initialValues: {
            username: '',
            password: ''
        },

        validate: {
            username: (e) => e.length < 2 ? 'Przynajmniej dwa znaki' : null,
            password: (e) => e.length < 2 ? 'Przynajmniej dwa znaki' : null
        }
    });

    const onLoginSuccess = () => {
        navigate('/');
    }

    const doLogin = (values: LoginFormData) => {
        const {username, password} = values;

        setLoading(true);

        authContext.login(username, password)
            .then(onLoginSuccess)
            .catch(Notifications.showFetchError)
            .finally(stopLoading);
    };

    useEffect(onMounted, []);

    return (
        <BackgroundImage h="100vh" src={backgroundImage}>
            <Container size={420} pt={rem(100)}>
                <Transition mounted={isFormMounted} transition="pop" duration={750} timingFunction="ease">
                    { styles =>
                        <Paper withBorder radius="md" p={30} style={styles}>
                            <form onSubmit={form.onSubmit(doLogin)}>
                                <TextInput
                                    withAsterisk
                                    placeholder="Nazwa użytkownika"
                                    size="md"
                                    key={form.key('username')}
                                    {...form.getInputProps('username')}
                                />

                                <PasswordInput
                                    withAsterisk
                                    placeholder="Hasło"
                                    mt="md"
                                    size="md"
                                    key={form.key('password')}
                                    {...form.getInputProps('password')}
                                />

                                <Button fz="sm" mt="md" loading={loading} fullWidth size="sm" type="submit">
                                    Zaloguj się
                                </Button>
                            </form>
                        </Paper>
                    }
                </Transition>
            </Container>
        </BackgroundImage>
    );
}

export default LoginPage;

// TODO: Login.tsx
//  - Improve login page
//  - Check assets caching for background image.
//  - Improve transitions, extract settings to static class or context.