import {ReactNode, useEffect, useState} from "react";
import dayjs from "dayjs";
import {Text} from "@mantine/core";

type DateTimeProps = {
    dateTime: string | undefined | null;

    /**
     * Fallback to use if dateTime is undefined or null.
     * If not set, default fallback is '-'.
     * @author Kacper Faber
     */
    fallback?: string;

    /**
     * Render formatted date time.
     * Default to <Text>{text}</Text>.
     * @param text
     */
    render?: (text: string) => ReactNode;
}

function DateTime(props: DateTimeProps) {
    const [text, setText] = useState<string>(props.fallback ?? '-');

    const reload = () => {
        const d = dayjs(props.dateTime);
        setText(d.format("YYYY-MM-DD HH:mm:ss"));
    };

    const render = () => {
        if (props.render) {
            return props.render(text);
        }

        return (
            <Text children={text} />
        )
    };

    useEffect(reload, []);

    return (
        <>
            {render()}
        </>
    );
}

export default DateTime;