import {ReactNode, useContext, useEffect, useState} from "react";
import {Box, Transition} from "@mantine/core";
import LayoutPage from "../contexts/_layout/LayoutPage";
import LayoutContext from "../contexts/_layout/LayoutContext";
import PageWrapperMode from "./PageWrapperMode";

type PageWrapperProps = {
    children: ReactNode | ReactNode[];

    /**
     * You can easily modify LayoutContext here.
     * @author Kacper Faber
     */
    page?: LayoutPage;

    /**
     * Use 'simple' to disable 'Card appearance' of content,
     * Only in 'default' mode we're using transitions.
     * @author Kacper Faber
     */
    mode?: PageWrapperMode;
}

/**
 * Wrap your pages inside a nice container.
 * In the future it will provide more functionality.
 * In 'default' mode we have nice transition.
 * @param props
 * @constructor
 */
function PageWrapper(props: PageWrapperProps) {
    const [transitionMounted, setTransitionMounted] = useState(false);
    const layoutContext = useContext(LayoutContext);

    const reloadLayoutPage = () => {
        layoutContext.setSelected(props.page);
    };

    const init = () => {
        setTransitionMounted(true);
    };

    useEffect(reloadLayoutPage, []);

    useEffect(init, []);

    if (props.mode === 'simple') {
        return (
            <Box p="md">
                {props.children}
            </Box>
        )
    }

    if (props.mode === 'simple_animated') {
        return (
            <Transition mounted={transitionMounted} transition="pop">
                {styles =>
                    <Box p="md" style={{ ...styles }}>
                        {props.children}
                    </Box>
                }
            </Transition>

        )
    }

    return (
        <Transition mounted={transitionMounted} transition="pop">
            {styles =>
                <Box p="md" style={{borderRadius: '25px', ...styles}} bg="dark.7" px="xl" py="xl">
                    {props.children}
                </Box>}
        </Transition>
    )
}

export default PageWrapper;