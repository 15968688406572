import {AppShell, NavLink} from "@mantine/core";
import {useContext} from "react";
import LayoutContext from "../../contexts/_layout/LayoutContext";
import {IconDevicesPc, IconDoorExit} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

type NavbarProps = {
    closeMobile: () => void;
}

function Navbar(props: NavbarProps) {
    const layoutContext = useContext(LayoutContext);
    const navigate = useNavigate();

    const onClick = (url: string) => {
        navigate(url);
        props.closeMobile();
    }

    return (
        <AppShell.Navbar bg="dark.7" withBorder={true} style={{ borderRadius: '0 0px 0 0' }}>
            <NavLink
                onClick={() => onClick('/')}
                label="Strona główna"
                active={layoutContext.selected == 'index'}
                leftSection={<IconDevicesPc size={30}/>}
            />

            <NavLink
                onClick={() => onClick('/computer')}
                label="Komputery"
                active={layoutContext.selected == 'computer_list'}
                leftSection={<IconDevicesPc size={30}/>}
            />

            <NavLink
                onClick={() => onClick('/kill')}
                label="Zabójstwa"
                active={layoutContext.selected == 'kill_list'}
                leftSection={<IconDoorExit size={30}/>}
            />
        </AppShell.Navbar>
    );
}

export default Navbar;

// TODO: Navbar.tsx
//  - Create nice fucking Navbar [this is shit]