import {ReactNode, useState} from "react";
import LayoutPage from "./LayoutPage";
import LayoutContext from "./LayoutContext";

type WrapLayoutContextProps = {
    children: ReactNode | ReactNode[];
}

/**
 * Wraps you children[s] in LayoutContext.
 * @param props
 * @constructor
 */
function WrapLayoutContext(props: WrapLayoutContextProps) {
    const [selected, setSelected] = useState<LayoutPage>();

    return (
        <LayoutContext.Provider
            value={{ selected, setSelected }}
            children={props.children}
        />
    );
}

export default WrapLayoutContext;