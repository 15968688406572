export interface IStorage {
    /**
     * Get access token from storage.
     * Returns null, if token is not set.
     * @returns string | null
     */
    getAccessToken(): string | null;

    /**
     * Set access token in storage.
     * You can delete token from storage the same way, by giving null.
     * @param accessToken
     * @returns void
     */
    setAccessToken(accessToken: string | null): void;
}

/**
 * Implementation of IStorage to store data in window.sessionStorage.
 * @author Kacper Faber
 */
class InSessionStorage implements IStorage {
    key = 'Access_Token';

    getAccessToken(): string | null {
        return window.sessionStorage.getItem(this.key);
    }

    setAccessToken(accessToken: string | null) {
        if (accessToken === null) {
            window.sessionStorage.removeItem(this.key);
            return;
        }
        window.sessionStorage.setItem(this.key, accessToken);
    }
}

const SessionInstance: IStorage = new InSessionStorage();

export default SessionInstance;