import DialogProps from "../components/DialogProps";
import Computer from "../../api/computer/Computer";
import {useForm} from "@mantine/form";
import {Button, Group, Modal, Stack, Textarea, TextInput} from "@mantine/core";
import EditQuery from "../../api/computer/EditQuery";
import ComputerApi from "../../api/computer/ComputerApi";
import Notifications from "../../utils/notifications/Notifications";
import {useEffect, useState} from "react";

type ComputerEditDialogBaseProps = {
    /**
     * Computer
     * @author Kacper Faber
     */
    computer?: Computer;
};

type ComputerEditDialogProps = ComputerEditDialogBaseProps & DialogProps;

/**
 * Simple dialog to edit computers.
 * Requires computer to be given.
 * @param props
 * @constructor
 */
function ComputerEditDialog(props: ComputerEditDialogProps) {
    const [loading, setLoading] = useState(false);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            title: '',
            description: '',
            domainName: '',
            userName: ''
        }
    });

    // /**
    //  * Converts text (HH:mm) to seconds.
    //  * Returns '' if given text is null or undefined.
    //  * @param seconds
    //  */
    // const getTextFromSeconds = (seconds: number | null | undefined) => {
    //     if (seconds === null || seconds === undefined) {
    //         return '';
    //     }
    //     return moment.utc(seconds * 1000).format("HH:mm");
    // }

    /**
     * Initialize form values.
     * To call after computer changed.
     * @author Kacper Faber
     */
    const init = () => {
        form.setValues({
            title: props.computer?.title ?? '',
            description: props.computer?.description ?? '',
            userName: props.computer?.userName ?? '',
            domainName: props.computer?.domainName ?? ''
        });
    }

    // const getSeconds = (s: string) => {
    //     if (s.length == 0) return null;
    //     const t = s.split(":");
    //     const h = parseInt(t[0]);
    //     const m = parseInt(t[1]);
    //     const secH = h * 60 * 60;
    //     return (m * 60) + secH;
    // }

    const doSubmit = (values: any) => {
        const id = props.computer?.id;

        if (id === undefined || id === null) {
            throw new Error("Could not submit. Missing computer.id.");
        }

        const editQuery: EditQuery = { ...values, id };

        setLoading(true);

        ComputerApi.editComputer(editQuery)
            .then(Notifications.showUpdateSuccess)
            .then(props.closeAndReload)
            .catch(Notifications.showFetchError)
            .finally(() => setLoading(false));
    };

    useEffect(init, [props.computer]);

    return (
        <Modal opened={props.opened} onClose={props.justClose} title="Edycja komputera">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack gap="md">
                    <TextInput
                        withAsterisk
                        label="Nazwa komputera"
                        placeholder="Nazwa komputera"
                        key={form.key('title')}
                        { ...form.getInputProps('title') }
                    />

                    <Textarea
                        label="Opis"
                        placeholder="Opcjonalny opis"
                        key={form.key('description')}
                        { ...form.getInputProps('description') }
                    />

                    <TextInput
                        disabled
                        label="Domena"
                        placeholder="Domena"
                        key={form.key('domainName')}
                        { ...form.getInputProps('domainName') }
                    />

                    <TextInput
                        disabled
                        label="Nazwa użytkownika"
                        placeholder="Nazwa użytkownika"
                        key={form.key('userName')}
                        { ...form.getInputProps('userName') }
                    />

                    {/*<TimeInput*/}
                    {/*    label="Czas bezczynności"*/}
                    {/*    key={form.key('maxIdle')}*/}
                    {/*    { ...form.getInputProps('maxIdle') }*/}
                    {/*    description="Ustaw puste, aby użyć wartości domyślnej."*/}
                    {/*/>*/}

                    <Group justify="right">
                        <Button
                            loading={loading}
                            fullWidth
                            type="submit"
                            children="Zapisz"
                        />
                    </Group>
                </Stack>
            </form>
        </Modal>
    );
}

export default ComputerEditDialog;

// TODO: ComputerEditDialog.tsx
//  - Use ComputerPageContext here.