import WrapAuthContext from "./contexts/auth/WrapAuthContext";
import AppRouter from "./router/AppRouter";
import WrapMantine from "./libraries/mantine/WrapMantine";

/**
 * Entire application.
 * @constructor
 */
function App() {
    return (
        // With mantine library.
        <WrapMantine>
            <WrapAuthContext>
                <AppRouter/>
            </WrapAuthContext>
        </WrapMantine>
    );
}

export default App;