import Program from "./Program";
import Page from "../Page";
import Api from "../Api";
import HttpClient from "../HttpClient";
import ListQuery from "./ListQuery";

// noinspection JSUnusedGlobalSymbols
class ProgramApi {
    /**
     * GET /api/program/
     * Get programs from API.
     * @param query
     */
    static getPrograms(query: ListQuery): Promise<Page<Program>> {
        const url = Api.getUrl('/api/program/');
        return HttpClient.get(url, { ...query });
    }
}

export default ProgramApi;