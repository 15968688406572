import ComputerMantineTable from "./mantine-table/ComputerMantineTable";

type ComputerTableViewProps = {}

/**
 * Render page of computers in table.
 * Table implementation is dependant of the component.
 * Currently, we're using Mantine DataTable.
 * @param _props
 * @constructor
 */
function ComputerTableView(_props: ComputerTableViewProps) {
    return (
        <ComputerMantineTable/>
    );
}

export default ComputerTableView;